import React from "react";

const Styles = ({ uniqueId, styles }) => {
  if (!styles) {
    return <></>;
  }

  return (
    <style>{`
        #${uniqueId} {
            width: ${styles.width};
            --plyr-color-main: ${styles.primaryColor};
            --plyr-video-control-color: ${styles.secondaryColor};
        }
`}</style>
  );
};

export default Styles;

/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useRef } from "react";
import Plyr from "plyr";
import Styles from "./Styles";
import { getFileExtension, generateUniqueId } from "./../../../wp-utils/v1";
import Default from "./templates/Default";

const VideoPlayer = ({ data = {}, options, styles, uniqueId = `svp${generateUniqueId(9)}` }) => {
  const { src, captions, poster, videos = [], quality } = data;
  const { muted, autoplay, repeat, playsinline } = options;
  const [player, setPlayer] = useState(null);
  const playerRef = useRef(null);
  const [video, setVideo] = useState(playerRef.current?.querySelector("video"));
  const [currentItem, setCurrentItem] = useState(0);

  useEffect(() => {
    if (player) {
      player.destroy();
    }
    if (muted) {
      options.storage = { enabled: false };
    }
    if (videos.length > 0) {
      options.ratio = "16:9";
      if (options.controls.indexOf("download") > 0) {
        options.controls.splice(options.controls.indexOf("download"), 1);
      }
    }
    setPlayer(new Plyr(playerRef.current?.querySelector("video"), { captions: { active: true, update: true, language: "en" }, ...options }));
  }, [options]);

  useEffect(() => {
    let source = src;
    if (videos?.length > 0) {
      const videoDetails = videos[currentItem];
      if (videoDetails && player) {
        playerRef.current.querySelector(".plyr__poster")?.setAttribute("style", `background-image: url("${videoDetails.playlist_item_poster}")`);
        playerRef.current.querySelector("video").src = videoDetails.playlist_item;
        source = videoDetails.playlist_item;
      }
      //   player?.play();
    }

    let ext = getFileExtension(source);
    if (ext === "m3u8" && window.Hls?.isSupported()) {
      // For more Hls.js options, see https://github.com/dailymotion/hls.js
      const hls = new Hls();
      hls.loadSource(source);
      hls.attachMedia(playerRef.current?.querySelector("video"));
      //   window.hls = hls;

      // Handle changing captions
      player?.on("languagechange", () => {
        setTimeout(() => (hls.subtitleTrack = player.currentTrack), 50);
      });
    }

    if (ext === "mpd" && window.shaka?.Player.isBrowserSupported()) {
      // Install built-in polyfills
      window.shaka.polyfill.installAll();
      const shakaInstance = new window.shaka.Player(playerRef.current?.querySelector("video"));
      shakaInstance.load(source);
    }
  }, [src, currentItem]);

  // console.log(options, videos);

  if (!src && videos?.length < 1 && quality?.length < 1) {
    return "<h3>Invalid Source</h3>";
  }

  return (
    <>
      <Styles uniqueId={uniqueId} styles={styles} />
      <div ref={playerRef} id={uniqueId} className="svp_player">
        <video data-poster={poster} autoPlay={autoplay} playsInline={playsinline} preload="metadata" mute={muted} loop={repeat} src={src || videos?.[0]?.playlist_item}>
          {Array.isArray(captions) &&
            captions.map((caption, index) => {
              const info = caption.label.split("/");
              return <track key={index} src={caption.vtt} kind="captions" srcLang={info[1] || info[0]} label={info[0] || ""} />;
            })}
          {Array.isArray(quality) &&
            quality.map((item, index) => {
              return <source key={index} src={item.vid_src} size={item.vid_size} type="video/mp4" />;
            })}
        </video>
        {Array.isArray(videos) && (
          <div className="svp_playlist">
            <Default player={player} currentItem={currentItem} setCurrentItem={setCurrentItem} videos={videos} />
          </div>
        )}
      </div>
    </>
  );
};

export default VideoPlayer;

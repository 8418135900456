import { render } from "react-dom";

import jsonParse from "./../../../wp-utils/v1/jsonParse";
import VideoPlayer from "../components/VideoPlayer";

import "./style.scss";

document.addEventListener("DOMContentLoaded", function () {
  const videoPlayers = document.querySelectorAll(".svpPlayer");

  videoPlayers?.forEach((player) => {
    const props = jsonParse(player.dataset.props);
    player.dataset.props = "";
    if (props) {
      render(<FrontEnd {...props} />, player);
    }
  });
});

const FrontEnd = (props) => {
  return <VideoPlayer {...props} />;
};

export default FrontEnd;
